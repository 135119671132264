import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import Container from "./Container";
import DecoratedLetter from "./DecoratedLetter";
import { colors, media } from "../theme";

const Wrapper = styled.header`
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: ${colors.background};
  border-bottom: 1px solid ${colors.grey};
  margin-bottom: 40px;
`;

const activeClassName = "active";

const NavLink = styled(Link)`
  display: inline-block;
  padding: 5px 10px;
  margin-left: 10px;
  &:first-of-type {
    margin-left: 10px;
  }
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal !important;
  color: ${colors.primary} !important;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  &.${activeClassName}, &:hover {
    color: ${colors.gold} !important;
    border-bottom: 3px solid ${colors.gold};
  }
  transition: all 0.25s linear;
`;

const A = NavLink.withComponent("a");

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-scrollbar {
    display: none;
  }
  /* Taken from Facebook's reactjs.org */
  @media (max-width: ${media.small}px) {
    mask-image: linear-gradient(
      to right,
      transparent,
      black 10px,
      black 90%,
      transparent
    );
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          navConfiguration {
            text
            path
            external
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <DecoratedLetter fill={colors.dark}>MC</DecoratedLetter>
        </Link>
        <Nav>
          {data?.site?.siteMetadata?.navConfiguration?.map((pagePath) => {
            if (pagePath.external) {
              return <A href={pagePath.path}>{pagePath.text}</A>;
            }
            return (
              <NavLink
                key={pagePath.path}
                to={pagePath.path === "/" ? "/" : `${pagePath.path}/`}
                activeClassName={activeClassName}
              >
                {pagePath.text}
              </NavLink>
            );
          })}
        </Nav>
      </Container>
    </Wrapper>
  );
};
