export const colors = {
  primary: "#494949",
  background: "#ffffff",
  grey: "#eeeeee",
  grey2: "#aaaaaa",
  darkgrey: "#455158",
  dark: "#2d2d2d",
  blue: "#6495ed",
  darkblue: "#4a6ea7",
  textlight: "#ffffffe6",
  gold: "#e67700",
};

export const media = {
  small: 600,
  medium: 780,
  large: 980,
};

export const markdown = {
  p: {
    marginTop: "20px",
  },

  h2: {
    borderTop: `1px solid ${colors.grey}`,
    marginTop: "40px",
    paddingTop: "40px",
    fontSize: "24px",
  },

  h3: {
    paddingTop: "30px",
  },

  ul: {
    marginTop: "10px",
  },

  li: {
    marginTop: "5px",
  },
};

export default {
  colors,
  media,
  markdown,
};
