import React from "react";
import { Helmet } from "react-helmet";
import favicon from "images/favicon.ico";

const DocumentHead = () => (
  <Helmet>
    <title>Mario Cagalj</title>
    <meta
      name="description"
      content="Mario is a university professor, working on info security, cryptography, web development and embedded programming."
    />
    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    <html lang="en" />
  </Helmet>
);

export default DocumentHead;
