import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 1000px;
  width: 90%;
`;

export default Container;
