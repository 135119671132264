import React from "react";
import styled from "@emotion/styled";
import theme from "gatsby-theme-googlesheets-personal-web/src/theme";
import Container from "gatsby-theme-googlesheets-personal-web/src/components/Container";
import Flex from "gatsby-theme-googlesheets-personal-web/src/components/Flex";
import GithubIcon from "images/GithubIcon";
import GoogleScholarIcon from "images/GoogleScholarIcon";

const FooterContainer = styled(Container)`
  flex-grow: 0;
`;

const Footer = styled.footer`
  margin-top: 40px;
  padding: 40px;
  padding: 40px;
  border-top: 1px solid ${theme.colors.grey};
  font-size: 16px;
  overflow: auto;
  flex-grow: 1;
`;

const Links = styled(Flex)`
  & > a:not(:first-of-type) {
    margin-left: 20px;
  }

  & > a {
    &:link,
    &:visited {
      color: ${theme.colors.darkgrey};
    }

    &:hover {
      color: ${theme.colors.gold};
    }
  }
`;

export default () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <Footer>
        <Flex direction="column" grow={1} halign="center">
          <Links>
            <a href="https://scholar.google.hr/citations?user=y3F7cwIAAAAJ">
              <GoogleScholarIcon />
            </a>
            <a href="https://github.com/mcagalj">
              <GithubIcon />
            </a>
          </Links>

          <p
            style={{
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {currentYear} Mario Čagalj @ FESB
          </p>
        </Flex>
      </Footer>
    </FooterContainer>
  );
};
