import React from "react";
import DocumentHead from "./DocumentHead";
import SiteContainer from "./SiteContainer";
import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";
import { MDXProvider } from "@mdx-js/react";
import components from "./shortcodes";
import "../css/styles.css";

const Layout = ({ children }) => (
  <SiteContainer>
    <DocumentHead />
    <Header />
    <Container>
      <MDXProvider components={components}>{children}</MDXProvider>
    </Container>
    <Footer />
  </SiteContainer>
);

export default Layout;
