import React from "react";
import { markdown } from "../theme";

// DesignSystem
const H2 = ({ children, props }) => (
  <h2 {...props} style={markdown.h2}>
    {children}
  </h2>
);

const H3 = ({ children, props }) => (
  <h3 {...props} style={markdown.h3}>
    {children}
  </h3>
);

const P = ({ children, props }) => (
  <p {...props} style={markdown.p}>
    {children}
  </p>
);

const UL = ({ children, props }) => (
  <ul {...props} style={markdown.ul}>
    {children}
  </ul>
);

const LI = ({ children, props }) => (
  <li {...props} style={markdown.li}>
    {children}
  </li>
);

// Shortcodes mapping
const components = () => ({
  h2: H2,
  h3: H3,
  p: P,
  ul: UL,
  li: LI,
});

export default components;
