import styled from "@emotion/styled";

const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

export default SiteContainer;
