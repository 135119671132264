import React from "react";
import styled from "@emotion/styled";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
`;

const DecoratedLetter = ({
  fill = "#000",
  color = "#fff",
  width = 36,
  height = 36,
  radius = 0,
  fontSize = 16,
  fontWeight = 500,
  children,
}) => (
  <Div>
    <svg
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g>
        <rect
          x="0"
          y="0"
          width={width}
          height={height}
          fill={fill}
          rx={radius}
          ry={radius}
        />
        <text
          x="50%"
          y="50%"
          fill={color}
          text-anchor="middle"
          dominant-baseline="central"
          font-weight={fontWeight}
          font-size={fontSize}
        >
          {children.toUpperCase()}
        </text>
      </g>
    </svg>
  </Div>
);

export default DecoratedLetter;
